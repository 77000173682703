import isPropValid from '@emotion/is-prop-valid'
import Link from '../link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	isPropValid(prop) && !['type', 'component', 'items', 'maxItemsForColumn', 'parentMaxItemsForColumn'].includes(prop)

const DropDownWrapper = styled('div')`
	padding: 0;
	display: block;
`
const DropDrownList = styled(List, {
	shouldForwardProp,
})`
	display: grid;
	grid-template-columns: ${({ countItems }) => `repeat(${countItems}, minmax(max-content, 1fr))`};
	grid-template-rows: 1fr;
	grid-gap: 20px;
	align-items: flex-start;
	padding: 0;
	width: 100%;
	padding-left: calc(12.5% + 150px);
	${({ theme }) => theme.breakpoints.between('1280', '1400')} {
		padding-left: 0;
	}
	.DropDrownList-item {
		[class*='banner-hover-trigger'] {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease-in-out;
			&._show {
				visibility: visible;
				opacity: 1;
			}
		}
		[class*='default-hover-trigger'] {
			opacity: 1;
			transition: all 0.2s ease-in-out;
			&._hide {
				visibility: hidden;
				opacity: 0;
			}
		}
	}
	&.parent_only-images {
		&.level-1 {
			height: auto;
		}
		li {
			&.image-menu {
				width: 100%;
			}
		}
	}
	&.parent_column-layout {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(${({ countItems }) => countItems ?? 1}, max-content);
		grid-auto-flow: column;
		justify-content: flex-start;
		gap: 0;
		.hasBanner {
			grid-column: ${({ countItems }) => (countItems >= 1 ? countItems + 1 : 1)};
			display: flex;
			justify-content: flex-end;
			grid-row-end: span ${({ countItems }) => (countItems >= 1 ? countItems + 1 : 1)};
		}
		.MuiListItem-root {
			&.level-1 {
				padding: 0 ${({ theme }) => theme.spacing(4)};
				&:first-of-type {
					padding-top: 45px;
				}
				&.image-menu {
					padding: 0;
					width: auto;
				}
			}
			&:nth-of-type(n
					+ ${({ parentMaxItemsForColumn }) =>
						parentMaxItemsForColumn >= 1 ? parentMaxItemsForColumn + 1 : -1}):not(.hasBanner) {
				margin-left: 50px;
				grid-column: 2;
			}
		}
	}
	&.parent_row-image-menu {
		padding-left: ${({ theme }) => theme.spacing(0)};
		grid-template-columns: repeat(${({ countItems }) => countItems - 1 ?? 1}, 1fr);
		.hasBanner {
			display: block;
			padding: 0;
			width: 100%;
			grid-column: 1/-1;
		}
	}

	.column-1 {
		display: grid;
		grid-template-columns: repeat(1, 427px);
		column-gap: 2px;
		justify-content: flex-end;
	}
	.column-2 {
		display: grid;
		grid-template-columns: repeat(2, 427px);
		column-gap: 2px;
		justify-content: flex-end;
	}
	.column-3 {
		display: grid;
		grid-template-columns: repeat(3, 427px);
		column-gap: 2px;
		justify-content: flex-end;
	}
	.column-4 {
		display: grid;
		grid-template-columns: repeat(4, 427px);
		column-gap: 2px;
		justify-content: flex-end;
	}
`

const DropDrownListItem = styled(ListItem, {
	shouldForwardProp,
})`
	display: block;
	padding: 0;
	padding-left: ${({ type }) => (type === 'cms_block' ? '0px' : '0px')};
	& .MuiList-root {
		display: block;
		padding: 0;

		& .MuiListItem-root {
			padding-left: 0;
		}
	}
	&.menu-item-current {
		> a {
			font-weight: ${({ theme }) => theme.typography.fontWeightExtraBold};
			&::after {
				width: 100%;
			}
		}
		&:hover {
			> a:after {
				background-color: ${({ theme }) => theme.palette.grey.main};
			}
		}
	}
	&.level-1 {
		padding: 80px ${({ theme }) => theme.spacing(2)} 40px;
		&.image-menu {
			padding: ${({ theme }) => theme.spacing(2)};
			min-width: 459px;
		}
	}
`

const NavLinkStyled = styled(Link)`
	color: inherit;
	cursor: pointer;
	display: inline-block;
	line-height: ${({ ...props }) => (props.variant === 'h6' ? '1' : '32px')};
	margin-bottom: ${({ ...props }) => (props.variant === 'h6' ? '20px' : '0')};
	font-weight: ${({ ...props }) => (props.variant === 'h6' ? '700' : '500')};
	font-size: ${({ ...props }) => (props.variant === 'h6' ? '16px' : '14px')};
	transition: color 0.2s;
	transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	&:not(.no-link):hover {
		color: ${({ theme }) => theme.palette.grey.main};
	}
	&:after {
		content: '';
		display: none;
		width: 0px;
		height: 1px;
		background-color: ${({ theme }) => theme.palette.primary.main};
		margin-top: -1px;
		transition: width 0.3s;
		transition-timing-function: ${({ theme }) => theme.transitions.easing.easeOut};
	}
	&.no-link {
		cursor: default;
	}
	&.item-view-all {
		display: block;
		width: fit-content;
		text-transform: uppercase;
		border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(10)};
		line-height: 1;
		padding-bottom: 4px;
		letter-spacing: 1px;
		font-weight: 600;
		margin-bottom: ${({ theme }) => theme.spacing(3)};
	}
`

const AdditionalBlock = styled('div')`
	width: 100%;
	padding: 12px 68px;
	text-align: center;
	border-top: 1px solid ${({ theme }) => theme.palette.grey.light};
	& p {
		margin: 0;
	}
`
const ChildrenWrapper = styled('div')`
	display: flex;
	gap: 15px;
	height: 100%;
`

export { DropDownWrapper, DropDrownList, DropDrownListItem, NavLinkStyled, AdditionalBlock, ChildrenWrapper }
