import { styled } from '@mui/system'
import MenuDropDown from '../menu-dropdown'

const shouldForwardProp = (prop) => !['top'].includes(prop)

const Overlay = styled('div', { shouldForwardProp })`
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	width: 100%;
	height: 100vh;
	left: 0;
	top: var(--header-height);
	display: none !important;
`

const MenuDropDownStyled = styled(MenuDropDown)`
	z-index: 1;
	border-top: 0;
	.MuiPaper-root {
		background: rgba(255, 255, 255, 0.7);
		backdrop-filter: blur(50px);
	}
`

export { Overlay, MenuDropDownStyled }
